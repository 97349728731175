/* ----- Extra small ----- */
@media screen and (max-width: 767px) {
    .container {
        max-width: 100%;
    }

    .logo {
        width: 190px;
    }

    .main-title {
        font-size: 1.3em;
        padding: 50px 0 30px;
    }

    .sectionPadding {
        padding: 50px 20px 100px 20px;
    }

    .subtitle-small-middle {
        font-size: 1.5em;
        margin: 40px 20px 30px 20px;
        padding: 0;
    }

    .industry-row {
        margin-top: 0;
    }

    .regular-text {
        padding-left: 20px;
        padding-right: 20px;
        font-size: 0.8em;
        text-align:left;
    }

    .header-title-box {
        width: 200px;
    }

    .title-over-header-image {
        height: 60px;
        font-size: 1.2em;
        padding: 15px 15px;
        margin-top: 20px;
    }

    .sub-title-small-left {
        padding-left: 20px;
        padding-right: 20px;
        font-weight: bold;
        font-size: 0.9em;
    }

    #nav-bar {
        height: 110px;
        padding: 40px 20px 10px 30px !important;
    }

    .sticky + #headerImage,  #headerImage{
        margin-top: 110px;
    }

    .facilitiesImages {
        margin-top: 30px;
    }

    #map > div > div {
        width: 400px !important;
    }

    .isoImages {
        margin: 20px 0 0 0;
        padding: 0 15px !important;
    }

    .aboutUsImages {
        margin: 20px 0 0 0;
    }

    .mobileNavItemsBox {
        display: block;
    }

    #mobileNavContainer {
        font-size: 1.2em;
    }

    .navItemsBox {
        display: none;
    }

    .footer-nav {
        margin-left: 10px;
        font-size: 0.8em;
    }

    .footerContactAddressText {
        padding: 20px 20px 0 20px;
        font-size: .8em;
        line-height: 1.2em;
    }

    #headerImage {
        height: 255px;
        background: url(/images/header/headerImage-xs.jpg) no-repeat 50% 50%;
        background-size: cover;
    }

    #aboutHeaderImage {
        height: 220px;
        background: url(/images/about/about-us-header-image-xs.jpg) no-repeat 50% 50%;
        background-size: cover;
    }

    #isoHeaderImage {
        height: 220px;
        background: url(/images/iso/ISO-header-image-md.jpg) no-repeat 50% 50%;
        background-size: cover;
    }

    #contactHeaderImage {
        height: 220px;
        background: url(/images/contact/contact-us-header-image-xs.jpg) no-repeat 50% 50%;
        background-size: cover;
    }

    #facilitiesHeaderImage {
        height: 220px;
        background: url(/images/facilities/facilities-header-image-xl.jpg) no-repeat 50% 50%;
        background-size: cover;
    }
}

/* ----- Medium ----- */
@media screen and (min-width: 768px) and (max-width: 991px) {
    .container {
        max-width: 100%;
    }

    .logo {
        width: 200px;
    }

    .main-title {
        font-size: 1.6em;
        padding: 50px 0 30px;
    }

    .sectionPadding {
        padding: 60px 20px 120px 20px;
    }

    .subtitle-small-middle {
        font-size: 1.6em;
        margin: 40px 20px 30px 20px;
        padding: 0;
    }

    .industry-row {
        margin-top: 0;
    }

    .regular-text {
        padding-left: 20px;
        padding-right: 20px;
        font-size: 0.9em;
        text-align:left;
    }

    .title-over-header-image {
        height: 80px;
        font-size: 1.3em;
        padding: 15px 20px;
        margin-top: 20px;
    }

    .sub-title-small-left {
        padding-left: 20px;
        padding-right: 20px;
        font-size: 0.9em;
        font-weight: bold;
    }

    #nav-bar {
        height: 110px;
        padding: 40px 20px 10px 30px !important;
    }

    .sticky + #headerImage,  #headerImage{
        margin-top: 110px;
    }

    .facilitiesImages {
        margin-top: 40px;
    }

    .footer-nav {
        margin-left: 15px;
    }

    .footerContactAddressText {
        padding: 20px 20px 0 20px;
        font-size: .8em;
        line-height: 1.2em;
    }

    #map > div > div {
        width: 400px !important;
    }

    .isoImages {
        margin: 20px 0 0 0;
        padding: 0 15px !important;
    }

    .aboutUsImages {
        margin: 20px 0 0 0;
    }

    .mobileNavItemsBox {
        display: block;
    }

    #mobileNavContainer {
        font-size: 1.2em;
    }

    .navItemsBox {
        display: none;
    }

    #headerImage {
        height: 255px;
        background: url(/images/header/headerImage-xs.jpg) no-repeat 50% 50%;
        background-size: cover;
    }

    #aboutHeaderImage {
        height: 220px;
        background: url(/images/about/about-us-header-image-xs.jpg) no-repeat 50% 50%;
        background-size: cover;
    }

    #contactHeaderImage {
        height: 220px;
        background: url(/images/contact/contact-us-header-image-xs.jpg) no-repeat 50% 50%;
        background-size: cover;
    }

    #isoHeaderImage {
        height: 220px;
        background: url(/images/iso/ISO-header-image-xs.jpg) no-repeat 50% 50%;
        background-size: cover;
    }

    #contactHeaderImage {
        height: 220px;
        background: url(/images/contact/contact-us-header-image-xs.jpg) no-repeat 50% 50%;
        background-size: cover;
    }

    #facilitiesHeaderImage {
        height: 220px;
        background: url(/images/facilities/facilities-header-image-xs.jpg) no-repeat 50% 50%;
        background-size: cover;
    }
}

/* ----- Large ----- */
@media screen and (min-width: 992px) and (max-width: 1199px) {
    .container {
        max-width: 960px;
    }

    .logo {
        width: 300px;
    }

    .main-title {
        font-size: 2.3em;
        padding: 70px 90px 30px 90px;
    }

    .sectionPadding {
        padding: 90px 10px 170px 10px;
    }

    .subtitle-small-middle {
        font-size: 1.7em;
        margin: 80px 20px 50px 20px;
    }

    .sub-title-small-left {
        padding-left: 20px;
        padding-right: 20px;
    }

    .regular-text {
        padding-left: 20px;
        padding-right: 20px;
        text-align: justify;
    }

    .aboutImages {
        padding: 0 20px;
    }

    #nav-bar {
        padding: 50px 1.5% 10px 100px;
        height: 150px;
    }

    .sticky + #headerImage,  #headerImage{
        margin-top: 196px;
      }

    .nav-itemsContainer {
        font-size: 0.75em;
        padding: 0;
        float: right;
    }

    .navBox {
        margin-left: 12px;
    }

    #homeNavBox {
        width: 50px;
    }

    #industriesNavBox {
        width: 95px;
    }

    #aboutNavBox {
        width: 55px;
    }

    #isoNavBox {
        width: 30px;
    }

    #facilitiesNavBox {
        width: 80px;
    }

    #contactNavBox {
        width: 75px;
    }
    
    #map {
        width: 400px;
    }

    #map > div > div {
        width: 400px !important;
    }

    .isoImages {
        margin: 0 0 15px 0 !important;
    }

    .mobileNavItemsBox {
        display: none;
    }

    .navItemsBox {
        display: block;
    }

    #headerImage {
        height: 745px;
        background: url(/images/header/headerImage-xl.jpg) no-repeat 50% 50%;
        background-size: cover;
    }
    
    #aboutHeaderImage {
        height: 500px;
        background: url(/images/about/about-us-header-image-xl.jpg) no-repeat 50% 50%;
        background-size: cover;
    }

    #isoHeaderImage {
        height: 500px;
        background: url(/images/iso/ISO-header-image-xl.jpg) no-repeat 50% 50%;
        background-size: cover;
    }
    
    #contactHeaderImage {
        height: 500px;
        background: url(/images/contact/contact-us-header-image-xl.jpg) no-repeat 50% 50%;
        background-size: cover;
    }

    #facilitiesHeaderImage {
        height: 500px;
        background: url(/images/facilities/facilities-header-image-xl.jpg) no-repeat 50% 50%;
        background-size: cover;
    }
}

/* ----- Extra Large ----- */
@media screen and (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }

    .logo {
        width: 374px;
    }

    .main-title {
        font-size: 2.8em;
        padding: 70px 90px 30px 90px;
    }

    .subtitle-small-middle {
        font-size: 1.85em;
        margin: 80px 20px 50px 20px;
    }

    .sectionPadding {
        padding: 120px 0 200px 0;
    }

    .regular-text {
        padding-left: 0;
        padding-right: 0;
        text-align: justify;
    }

    .nav-itemsContainer {
        padding: 0;
    }

    .sticky + #headerImage,  #headerImage{
        margin-top: 196px;
      }

    #isoNavBox {
        width: 35px;
    }

    .isoImages {
        margin: 0 0 15px 0 !important;
    }

    .mobileNavItemsBox {
        display: none;
    }

    .navItemsBox {
        display: block;
    }

    #headerImage {
        height: 745px;
        background: url(/images/header/headerImage-xl.jpg) no-repeat 50% 50%;
        background-size: cover;
    }

    #aboutHeaderImage {
        height: 500px;
        background: url(/images/about/about-us-header-image-xl.jpg) no-repeat 50% 50%;
        background-size: cover;
    }

    #isoHeaderImage {
        height: 500px;
        background: url(/images/iso/ISO-header-image-xl.jpg) no-repeat 50% 50%;
        background-size: cover;
    }

    #contactHeaderImage {
        height: 500px;
        background: url(/images/contact/contact-us-header-image-xl.jpg) no-repeat 50% 50%;
        background-size: cover;
    }

    #facilitiesHeaderImage {
        height: 500px;
        background: url(/images/facilities/facilities-header-image-xl.jpg) no-repeat 50% 50%;
        background-size: cover;
    }
}

/* custom */
/* ----- Large ----- */
@media screen and (min-width: 1200px) and (max-width: 1350px) {
    #nav-bar {
        padding: 80px 0 10px 60px;
    }
    .container {
        max-width: 1050px;
    }
    
    .logo {
        width: 340px;
    }

    .nav-itemsContainer {
        font-size: 0.9em;
        padding: 0 0 0 30px;
    }

    #homeNavBox {
        width: 55px;
    }

    #industriesNavBox {
        width: 110px;
    }

    #aboutNavBox {
        width: 65px;
    }

    #isoNavBox {
        width: 35px;
    }

    #facilitiesNavBox {
        width: 95px;
    }

    #contactNavBox {
        width: 87px;
    }

    .title-over-header-image {
        height: 80px;
        font-size: 1.5em;
        padding: 18px 48px 0px;
        margin-top: 6px;
    }

    .header-title-box {
        width: 320px;
        margin: -56px auto 0!important;
    }

    #map > div > div {
        width: 450px !important;
    }
}