body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  text-align: justify;
}

.wrapper {
  position:relative;
  float: left;
  width:100%;
  overflow: hidden;
}

.container {
  margin:0 auto;
  max-width: 1200px;
}

/* COMMON */
.text-center {
  text-align: center;
}

.padding-top-140 {
  padding-top: 140px;
}

.padding-top-120 {
  padding-top: 120px;
}

.padding-top-80 {
  padding-top: 80px;
}

.padding-top-70 {
  padding-top: 70px;
}

.padding-top-40 {
  padding-top: 40px;
}

.padding-bottom-200 {
  padding-bottom: 200px;
}

.padding-bottom-140 {
  padding-bottom: 140px;
}

.padding-bottom-100 {
  padding-bottom: 100px;
}

.padding-bottom-40 {
  padding-bottom: 40px;
}

.padding-left-20 {
  padding-left: 20px;
}

.padding-right-20 {
  padding-right: 20px;
}

.margin-top-100 {
  margin-top: 100px;
}

.margin-top-80 {
  margin-top: 80px;
}

.margin-top-70 {
  margin-top: 70px;
}

.margin-top-40 {
  margin-top: 40px;
}

.margin-top-20 {
  margin-top: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.margin-bottom-80 {
  margin-bottom: 80px;
}

.margin-bottom-60 {
  margin-bottom: 60px;
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.clear-fix {
  position: relative;
  clear: both;
}

label {
  font-size: 0.7em;
  margin: 0;
  padding: 0;
  width: 100%;
}


/* nav */
#nav-bar {
  position: relative;
  float: left;
  width: 100%;
  height: 196px;
  background-color: #FFF;
  padding: 80px 30px 10px 60px;
  text-align: center;
  z-index: 2;
  box-sizing: border-box;
}

.nav-itemsContainer {
  position: relative;
  float: left;
}

.nav-items {
  color: #000;
}

.nav-items:hover {
  text-decoration: none;
  font-weight: bold;
  color: #336699;
}

.navBox {
  position: relative;
  float: left;
  padding-top: 40px;
  height: 70px;
  margin-left: 15px;
  text-align: center;
}

.navBox:first-child {
  margin-left: 0;
}

#homeNavBox {
  width: 60px;
}

#industriesNavBox {
  width: 125px;
}

#aboutNavBox {
  width: 70px;
}

#isoNavBox {
  width: 35px;
}

#facilitiesNavBox {
  width: 110px;
}

#contactNavBox {
  width: 100px;
}

.sticky {
  position: fixed !important;
  top: 0;
  width: 100%  !important;
  z-index: 2;
}

.navActive {
  font-weight: bold;
  color: #336699;
}

/* logo */
.logo {
  position: relative;
  float: left;
}


/* titles and headers */
.main-title {
  text-align:center;
  font-size: 2.8em;
  padding: 70px 90px 30px 90px;
}

.sub-title {
  text-align:center;
  font-size: 1.8em;
  width: 500px;
  margin: 0 auto;
  padding: 30px 50px;
  background-color: rgb(0, 225, 255);
  background-color: rgb(0, 225, 255, 0.5);
}

.sub-title-with-blue-bg {
  text-align: center;
  font-size: 1.8em;
  width: 500px;
  margin: 0 auto !important;
  color: #000;
}

.sub-title-small-left {
  text-align: left;
  font-size: 1.7em;
  padding: 25px 0 10px 0;
  margin: 0 !important;
}

.subtitle-small-middle {
  text-align: center;
  font-size: 1.85em;
  padding: 20px 0;
  color: #336699;
  border: 1px solid #336699;
  border-width: 0px 0px 1px 0px;
}

.table-title {
  text-align: left;
  font-weight: bold;
  color: #000;
  font-size:0.9em;
}

.form-title {
  position: relative;
  float: left;
  width: 100%;
  text-align: left;
  font-weight: bold;
  color: #336699;
  font-size:0.9em;
  border: 1px solid #336699;
  border-width: 0px 0px 1px 0px;
  margin: 0 0 20px 0;
}

/* text and paragraph */
.regular-text {
  text-align: justify;
  font-size: 1em;
}

/* colors */
.regular-text-color {
  color: #000;
}

.main-title-color {
  color: #000;
}

.sub-title-color {
  color: #FFF;
}


/* SECTION */
.section {
  position: relative;
  float:left;
  width:100%;
}

.sectionPadding {
  padding: 120px 0 200px 0;
}

.header-title-box-container {
  position: relative;
  float: left;
  width: 100%;
  height: 40px;
  text-align: center;
}

/*
.header-title-box {
  position:absolute;
  height: 100px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
}
*/

.header-title-box {
  position: relative;
  height: 100px;
  width: 355px;
  margin: -60px auto 0 auto !important;
}

.title-over-header-image {
  position: relative;
  text-align: center;
  height: 100px;
  color: #FFF;
  font-size: 1.8em;
  padding: 20px 50px 30px 50px;
  margin-top: -30px;
  background-color: rgb(0, 166, 255);
  background-color: rgb(0, 166, 255, 0.65);
  line-height:80%;
}


/* INDUSTRY ITEMS */
.section-industries {
  margin: 0;
  padding: 0;
}

.industry-item {
  margin: 0 0 40px 0;
}

.industry-item-container {
  position: relative;
  border: 1px solid #336699;
  height: 100%;
}

.industry-item-container:hover {
  border: 1px solid #112244;
}

.industry-item-title-bar {
  position: relative;
  height: 50px;
  line-height: 50px;
  background-color: #336699;
  color: #FFF;
  font-size: 0.8em;
}

.industry-item-title-bar:hover {
  background-color: #112244;
}

.industry-item-text-container {
  position: relative;
  float: left;
  width: 100%;
  padding: 20px 20px;
}

.industry-item-text {
  font-size: 0.7em;
  text-align: left;
}

.titleContainerWithBlueBg {
  position: relative;
  width: 500px;
  margin: 0 auto;
}

.smallContainerWithLightBlueBg {
  position: relative;
  width: 100%;
  float: left;
  height: 45px;
  background-color: rgb(0, 225, 255);
  background-color: rgb(0, 225, 255, 0.5);
}

/* ABOUT US ITEMS */
.section-about-us {
  margin: 0;
  padding: 0;
}

/* ISO CERT ITEMS */
.iso-images {
  margin-bottom: 16px;
}

/* FACILITIES ITEMS - TABLES */
table {
  border-collapse: collapse;
  text-align: left;
}

.facilitiesTable {
  position: relative;
  width: 100%;
  margin: 20px 0 40px 0;
  padding: 0 20px;
}

th {
  padding: 5px 10px;
  background-color: #336699;
  color: white;
  font-weight: normal;
  font-size: 0.9em;
}

th:first-child {
  padding-left: 20px;
}

.facilitiesTable td {
  padding: 0 10px;
  vertical-align: top;
  font-size: 0.85em;
  border: 1px solid #bbb;
  border-width: 0 0 1px 0;
}

td:first-child {
  padding-left: 20px;
}

.facilitiesTable tr:hover {
  background-color: #eee;
}

.nameColumn {
  min-width: 160px;
  max-width: 220px;
}

.modelColumn {
  min-width: 100px;
}

.qtyColumn {
  min-width: 70px;
}

.xAxisColumn {
  min-width: 90px;
}

.yAxisColumn {
  min-width: 90px;
}

.zAxisColumn {
  min-width: 90px;
}

.maxSwingColumn {
  min-width: 140px;
}

.additionalInfoColumn {
  min-width: 200px;
  max-width: 500px;
  padding-right: 20px;
}

.facility-images {
  padding: 40px 20px 0 20px;
}

/* CONTACT US ITEMS */
.section-contact-us {
}

.contactAddressText {
  position:relative;
  float:left;
  width: 100%;
  padding: 20px 20px 20px 85px;
  font-size: 0.9em;
}

#map {
  position: relative;
  float: left;
  width: 600px;
  height: 400px;
}

.contactFormContainer {
  position:relative;
  float:left;
  min-height: 300px;
  padding-top: 20px;
}

.contactFormContainer input[type="text"], input[type="email"] {
  position: relative;
  float:left;
  width:100%;
  padding: 5px 10px;
  margin: 0;
  background-color: #eee;
  border: none;
  font-size: 0.9em;
}

.contactFormContainer td {
  padding: 0;
}

.contactFormContainer td:nth-child(even) {
  padding-left: 10px;
}

.contactFormContainer td:nth-child(odd) {
  padding-right: 10px;
}

.contactFormContainer td[colspan="2"] {
  padding: 0;
}

.autoscale-img {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.message {
  position:relative;
  float: left;
  width: 100%;
  min-height: 200px;
  max-height: 400px;
  max-width: 100%;
  background-color: #eee;
  border: none;
  padding: 10px;
}

#reason {
  position: relative;
  float:left;
  width: 100%;
  margin:0;
  padding: 10px;
  background-color: #ddd;
  border:none;
  font-size: 0.8em;
}

.clear-button {
  padding: 10px 20px;
  background-color: #336699;
  color: #FFF;
  font-size: 0.9em;
}

.clear-button:hover {
  background-color: #112244;
  color: #FFF;
}

.submit-button {
  padding: 10px 40px;
  background-color: #eee;
  color: #000;
  font-size: 0.9em;
}

.submit-button:hover {
  background-color: #336699;
  color: #FFF;
}

/* FOOTER ITEMS */
.section-footer {
  position: relative;
  float: left;
  width:100%;
}

.footer {
  position: relative;
  float: left;
  width:100%;
  background-color: #112244;
  color: #FFF;
  height: 300px;
  font-size: 0.8em;
  padding-top: 50px;
}

.footer-nav {
  color: #FFF;
  position:relative;
  float:left;
  margin-left: 30px
}

.footerContactAddressText {
  position: relative;
  float: left;
  width: 100%;
  padding: 0 20px;
  font-size: 0.9em;
  line-height: 1.6em;
}

.footer-copyright {
  margin-top: 40px;
  font-size: 0.75em;
}



.mobile-container {
  position: absolute;
  right: 0;
  background-color: #FFF;
  color: white;
  overflow: visible;
  transition: all 1s;
}

.topnav {
  overflow: hidden;
  background-color: #333;
  position: relative;
  clear:both;
  display: none;
}

.topnav #myLinks {
  display: none;
}

.topnav a {
  color: white;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  display: block;
  text-align: left;
  width:100%;
  clear: both;
}

.mobileNavItemsBox button {
  background: transparent;
  display: block;
  position: relative;
  float: right;
  color: black;
  border: none;
  clear: both;
}

.mobileNavItemsBox button:focus, .mobileNavItemsBox button:active {
  outline: none;
}

.topnav a:hover {
  background-color: #ddd;
  color: black;
}

.mobileNavActive {
  background-color: #4CAF50;
  color: white;
}

.closeNav {
  display: none;
  color: white;
  padding: 10px;
  box-sizing: content-box;
}

.openNav {
  display: block;
  color: black;
  padding: 10px;
  box-sizing: content-box;
}